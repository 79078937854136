import React from 'react';
import { FaPhone, FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaSquareXTwitter } from "react-icons/fa6";

function Footer() {
  return (
    <div>
        <footer className='bg-zinc-800 text-slate-50 flex flex-row items-start justify-evenly p-6'>
            <div>
                <h2 className='text-md text-green-600 font-semibold uppercase py-2'>Quick links</h2>
                <ul className=" ">
                    <li className='py-2'>
                        <a href="/" className='hover:text-green-600'>Home</a>
                    </li>
                    <li className='py-2'>
                        <a href="/about" className='hover:text-green-600'>About Us</a>
                    </li>
                    <li className='py-2'>
                        <a href="/services" className='hover:text-green-600'>Our Services</a>
                    </li>
                    <li className='py-2'>
                        <a href="/contact" className='hover:text-green-600'>Contact</a>
                    </li>
                </ul>
            </div>
            <div>
                <h2 className='text-md text-green-600 font-semibold uppercase py-2'>Get in touch</h2>
                <ul class=" ">
                    <li className='flex flex-row items-center gap-2 py-2'>
                        <FaPhone/>
                        <div className="flex flex-col">
                            <span class="phone-number">+355(0)69-576-6340</span>
                            {/* <span>(9:30-5pm EST Mon - Fri)</span> */}
                        </div>
                    </li>
                    <li className='flex flex-row items-center gap-2 py-2 '>
                        <MdEmail/>
                        <span class="email">dci.research.services@gmail.com</span>
                    </li>
                    <li className='flex flex-row gap-2 py-2'>
                        <a href='https://facebook.com/'>
                            <FaFacebookSquare className='text-green-600 text-3xl'/>
                        </a>
                        <a href='https://instagram.com/'>
                            <FaInstagramSquare className='text-green-600 text-3xl' />
                        </a>
                        <a href='https://x.com/'>
                            <FaSquareXTwitter className='text-green-600 text-3xl'/>
                        </a>
                    </li>
                </ul>
            </div>
            <div>
                <h2 className='text-md text-green-600 font-semibold uppercase py-2'>Sign up to our newsletter</h2>
                <form class="newsletter-form" action="/" method="POST">
                    <input class="newsletter-email" type="text" placeholder="Newsletter Sign-up"/>
                    <input class="shop-now-btn newsletter-btn"
                        type="submit" value="Submit"/>
                </form>
            </div>
        </footer>
    </div>
  )
}

export default Footer;