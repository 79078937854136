import React from 'react';
import HeaderButton from './HeaderButton';
import logo from '../images/dci-logo.png';

function Header() {
  const headerButtons = [
    { label: "Home", path: "/" },
    { label: "About Us", path: "/about" },
    { label: "Our Services", path: "/services" },
    { label: "Contact", path: "/contact" }
  ];

  const renderHeaderBtns = headerButtons.map((headerButton) => (
    <HeaderButton
      key={headerButton.label}
      optionTitle={headerButton.label}
      to={headerButton.path}
    />
  ));

  return (
    <div className='flex flex-row justify-between items-center w-full h-20 text-lg bg-zinc-800 px-4'>
      <a href="/">
        <img src={logo} className='h-16 p-2' alt="company logo" />
      </a>
      <div className='flex flex-row h-full'>
        {renderHeaderBtns}
      </div>
    </div>
  );
}

export default Header;
