import React from 'react'

function HeroOther({bgImg, text}) {
  return (
    <div className='relative w-full bg-cover bg-center' style={{ backgroundImage: `url(${bgImg})`, height: '250px' }}>
        <div className="absolute inset-0 bg-zinc-900 bg-opacity-80 flex items-center justify-center">
            <div className="text-center text-slate-50	 px-20 flex flex-col items-center gap-4">
            <h1 className="text-5xl font-bold mb-4">{text}</h1>
            </div>
        </div>
    </div>
  )
}

export default HeroOther