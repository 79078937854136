import React from 'react';
import ServiceCardDesc from './ServiceCardDesc';
import { IoIosPaper } from "react-icons/io";
import { MdGroups } from "react-icons/md";
import { GoGraph } from "react-icons/go";
import { BiSolidReport } from "react-icons/bi";

function WhatWeOffer() {
    const whatWeOfferCards = [
        {
            icon: <IoIosPaper className='text-green-600 text-5xl' />,
            title: "Research Design and Planning",
            description: [
                "Research design assistance",
                "Literature review assistance",
                "Questionnaire and scale development"
            ]
        },
        {
            icon: <MdGroups className='text-green-600 text-5xl' />,
            title: "Data Gathering",
            description: [
                "Focus group sessions",
                "Delphi groups sessions",
                "Depth interviews",
                "Various projective techniques"
            ]
        },
        {
            icon: <GoGraph className='text-green-600 text-5xl' />,
            title: "Data Analysis",
            description: [
                "Quantitative data analysis",
                "Qualitative data analysis"
            ]
        },
        {
            icon: <BiSolidReport className='text-green-600 text-5xl' />,
            title: "Reporting and Support",
            description: [
                "Research report preparation",
                "Ongoing consultancy and support"
            ]
        },
    ]

    const renderWhatWeOfferCards = whatWeOfferCards.map(card => (
        <ServiceCardDesc
            key={card.title}
            iconType={card.icon}
            cardTitle={card.title}
            cardDescription={card.description}
        />
    ));

    return (
        <div className='flex flex-row items-start justify-evenly gap-10 p-10 h-80'>
            <div className="w-80 flex flex-col items-center justify-center gap-2 bg-white p-6 h-full w-72">
                <h2 className="text-2xl text-center font-bold mb-2">What we offer</h2>
                <p className="text-gray-600 text-center text-sm">Discover comprehensive research solutions designed to meet your unique needs, from initial design to final analysis and reporting.</p>
                <a href='/services' className='border-b-2'>Read more</a>
            </div>
            {renderWhatWeOfferCards}
        </div>
    );
}

export default WhatWeOffer;
