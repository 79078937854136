import React from 'react';
import { FaMedal, FaRegChartBar   } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import ServiceCard from './ServiceCard';


function HeroCards() {
  const heroCardsInfo = [
    {icon: <IoMdSettings className='text-green-600 text-5xl'/>, title: "Innovative Solutions", description: "Pioneering new approaches to research challenges."},
    {icon: <FaMedal className='text-green-600 text-5xl'/>, title: "Quality Services", description: "Ensuring high standards in every aspect of our work."},
    {icon: <FaRegChartBar className='text-green-600 text-5xl'/>, title: "Results-Driven", description: "Focused on delivering measurable outcomes and impactful results."}
  ]

  const renderHeroCards = heroCardsInfo.map((card) => {
    return <ServiceCard
      iconType={card.icon}
      cardTitle={card.title}
      cardDescription={card.description}
    />
  })

  return (
    <div className='flex flex-row items-center justify-evenly -mt-10 z-50'>
      {renderHeroCards}
    </div>
  )
}

export default HeroCards;