import React from 'react';

function ServiceCardDesc({ iconType, cardTitle, cardDescription }) {
    return (
        <div className="w-80 flex flex-col items-center bg-white shadow-md p-6 w-72 h-full">
            <div className="text-green-600 text-5xl mb-2">{iconType}</div>
            <h2 className="text-lg text-center font-bold mb-2">{cardTitle}</h2>
            <ul className="list-dash list-inside text-gray-600 text-left text-sm">
                {cardDescription.map((desc, index) => (
                    <li key={index}>{desc}</li>
                ))}
            </ul>
        </div>
    );
}

export default ServiceCardDesc;