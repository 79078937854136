import React from 'react';
import heroImage from '../images/hero-img.jpg';
import SimpleButton from './SimpleButton';

function Hero() {
  return (
    <div className='relative w-full bg-cover bg-center' style={{ backgroundImage: `url(${heroImage})`, height: '500px' }}>
      <div className="absolute inset-0 bg-zinc-900 bg-opacity-80 flex items-center justify-center">
        <div className="text-center text-slate-50	 px-20 flex flex-col items-center gap-4">
          <h1 className="text-5xl font-bold mb-4">Comprehensive Research Services Tailored to Your Needs</h1>
          <p className="text-2xl">We specialize in delivering comprehensive research solutions that empower informed decisions and drive strategic outcomes. With a commitment to excellence and innovation, we offer tailored consultancy and support to meet your unique research needs.</p>
          <SimpleButton btnName={"Read more"} to={'/about'}/>
        </div>
      </div>
    </div>
  );
}

export default Hero;
