import React from 'react';
import ServiceCard from './ServiceCard';
import { FaHandshake } from "react-icons/fa";
import { IoShieldCheckmark } from "react-icons/io5";
import { FaPeopleGroup } from "react-icons/fa6";
import { GrDirections } from "react-icons/gr";

function WhoWeAre() {

  const whoWeAreCards = [
    {
        icon: <FaHandshake className='text-green-600 text-5xl' />,
        title: "Collaborative Partnership",
        description: ["We work hand-in-hand with our clients, fostering a true partnership to achieve shared goals."]
    },
    {
        icon: <IoShieldCheckmark className='text-green-600 text-5xl' />,
        title: "Ethical Integrity",
        description: ["Our unwavering commitment to ethical standards ensures transparency and trust in every project."]
    },
    {
        icon: <GrDirections className='text-green-600 text-5xl' />,
        title: "Adaptability and Flexibility",
        description: ["We pride ourselves on our ability to adapt and tailor our solutions to meet evolving client needs."]
    },
    {
        icon: <FaPeopleGroup className='text-green-600 text-5xl' />,
        title: "Expert Team",
        description: ["Our team of seasoned experts brings extensive knowledge and dedication to every research endeavor. "]
    },
  ] 

  const renderWhoWeAreCards = whoWeAreCards.map(card => (
    <ServiceCard
        key={card.title}
        iconType={card.icon}
        cardTitle={card.title}
        cardDescription={card.description}
    />
  ));


  return (
    <div className='flex flex-row items-start justify-evenly gap-10 mx-10 my-10 h-64'>
      {renderWhoWeAreCards}
    </div>
  )
}

export default WhoWeAre;