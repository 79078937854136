import React from 'react';

function ServiceCard({ iconType, cardTitle, cardDescription }) {
    return (
        <div className="w-80 flex flex-col items-center gap-2 bg-white shadow-md p-6 h-full">
            <div className="text-green-600 text-5xl mb-4">{iconType}</div>
            <h2 className="text-xl text-center font-bold mb-2">{cardTitle}</h2>
            <p className="text-gray-600 text-center">{cardDescription}</p>
        </div>
    );
}

export default ServiceCard;
