import React from 'react';

function Description({ paragraphs }) {

  console.log(paragraphs);
  return (
    <div className='mx-20 my-10 text-xl text-zinc-800 flex flex-col gap-10'>
      {paragraphs.map((paragraph, index) => (
        <p key={index}>
          {paragraph}
        </p>
      ))}
    </div>
  );
}

export default Description;
