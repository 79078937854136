import React from 'react';

function ContactForm() {
  return (
    <div className="flex justify-center items-center my-10">
      <form className="w-1/2 p-6 bg-neutral-50 shadow-lg ">
        <h2 className="text-2xl font-semibold text-green-600 mb-6 capitalize">Drop us a message</h2>
        {[
          { name: 'name', type: 'text', placeholder: 'Your Name' },
          { name: 'email', type: 'email', placeholder: 'Your Email' },
          { name: 'subject', type: 'text', placeholder: 'Your Subject' },
        ].map((field, index) => (

          <div className="mb-4" key={index}>
            <input
              name={field.name}
              type={field.type}
              placeholder={field.placeholder}
              className="w-full p-3 border border-zinc-300 focus:outline-none focus:ring-2 focus:ring-green-600"
            />
          </div>

        ))}

        <div className="mb-4">
          <textarea
            name="message"
            placeholder="Your Message"
            className="w-full p-3 border border-zinc-300 focus:outline-none focus:ring-2 focus:ring-green-600"
            rows="5"
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-green-600 text-white py-2 px-5 hover:bg-green-700 uppercase"
          >
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
