import React from 'react'
import Hero from '../components/Hero';
import HeroCards from '../components/HeroCards';
import AboutUs from '../components/AboutUs';
import WhatWeOffer from '../components/WhatWeOffer';

function HomePage() {
  return (
    <div className='flex flex-col'>
      <Hero/>
      <HeroCards/>
      <AboutUs/>
      <WhatWeOffer/>
    </div>
  )
}

export default HomePage;