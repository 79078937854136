import React from 'react';
import { Link } from 'react-router-dom';

function HeaderButton({ optionTitle, to }) {
  return (
    <div className='h-full flex items-center'>
      <Link to={to} className='flex items-center px-4 text-slate-50 hover:text-green-600'>
        {optionTitle}
      </Link>
    </div>


  );
}

export default HeaderButton;


