import React from 'react';
import ourServicesImg from '../images/hero-img-our-services.jpg';
import HeroOther from '../components/HeroOther';
// import Description from '../components/Description';

function ServicesPage() {
  // const paragraphs = [
  //   `Don’t hesitate to reach out with the contact information below, or send a message using the form.`,
  // ];

  return (
    <div>
      <HeroOther bgImg={ourServicesImg} text={'Our Services'}/>
      {/* <Description paragraphs={paragraphs}/> */}
    </div>
  )
}

export default ServicesPage;