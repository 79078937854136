import React from 'react'
import contactImg from '../images/hero-img-contact.jpg';
import HeroOther from '../components/HeroOther';
import Description from '../components/Description';
import ContactForm from '../components/ContactForm';

function ContactPage() {
  const paragraphs = [
    `Don’t hesitate to reach out with the contact information below, or send a message using the form.`,
  ];

  return (
    <div>
      <HeroOther bgImg={contactImg} text={'Contact Us'}/>
      <Description paragraphs={paragraphs}/>
      <ContactForm/>
    </div>
  )
}

export default ContactPage;