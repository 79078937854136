import React from 'react';
import aboutUsImg from '../images/hero-img-about-us.jpg';
import HeroOther from '../components/HeroOther';
import Description from '../components/Description';
import WhoWeAre from '../components/WhoWeAre';

function AboutUsPage() {
  const paragraphs = [
    `"DC&I Research Services and Consultancy" specializes in offering tailored research solutions to meet the diverse needs of individuals, businesses, and organizations. Our expertise spans every phase of the research process, from initial design through meticulous data analysis to the preparation of comprehensive final reports. Whether you're seeking market research to launch a new product, academic research for a scholarly publication, or strategic insights to inform business decisions, our dedicated team is committed to delivering actionable results that align with your specific objectives.`,
    `With a focus on quality and precision, we leverage advanced methodologies and industry best practices to ensure that each project is conducted with rigor and integrity. Our consultants possess deep domain knowledge across various sectors, allowing us to offer insightful perspectives and innovative approaches tailored to your unique requirements. Whether you require quantitative analysis, qualitative research, or a combination of both, "DC&I Research Services and Consultancy" is your trusted partner in achieving research excellence and driving informed decision-making.`
  ];

  return (
    <div>
      <HeroOther bgImg={aboutUsImg} text={'About Us'}/>
      <Description paragraphs={paragraphs} />
      <WhoWeAre/>
    </div>
  )
}

export default AboutUsPage;