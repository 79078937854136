import React from 'react';
import SimpleButton from './SimpleButton';
import aboutUsImg from '../images/about-us-img.jpg';

function AboutUs() {
    return (
        <div className='relative bg-zinc-800  text-slate-50 flex flex-row items-center justify-center mt-10 h-96'>
            <div className='w-1/2 h-full relative'>
                <img src={aboutUsImg} alt='' className='object-cover w-full h-full'/>
                <div className='absolute top-0 left-0 w-full h-full bg-zinc-800 opacity-70'></div>
            </div>
            <div className='w-1/2 flex flex-col items-center justify-center p-24 gap-10 h-full'>
                <p className='text-center text-xl'>
                    <span className='italic'>“DC&I Research Services and Consultancy”</span> is a research company which provides customized research services to individuals, businesses and organizations. It may support a wide range of research aspects, from research design to data analysis and final report preparation.
                </p>
                <SimpleButton btnName={"Read more"} to={'/about'}/>
            </div>
        </div>
    );
}

export default AboutUs;
