import React from 'react'

function SimpleButton({btnName, to}) { 
  return (
    <div className='my-3'>
      <a href={to} className='bg-green-600 text-slate-50 w-fit py-3 px-5 hover:bg-green-700'>{btnName}</a>
    </div>
  )
}

export default SimpleButton;